import React from 'react'

import LayoutOB from '../components/layoutob.js'
import { useLocation } from '@reach/router'
import SimpleService from '../components/StartPaidServiceSimple'



export default function ServicePage() {

    const location = useLocation();
    var searchParams = new URLSearchParams(location.search);
    var countryParam = searchParams.get("country");
    var destinationParam = searchParams.get("dest");

    let country ="CH"
    const url = typeof window !=="undefined" ? window.location.href :"";
  
    if(url.includes(".com")){
      country ="DE"
    }
    return (
        <LayoutOB location={country} >
            <SimpleService country={countryParam} destination={destinationParam}/>
        </LayoutOB>
    )
}

