import theme from "./theme"

import {makeStyles} from "@material-ui/core/styles"


export default makeStyles(theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  stepHeader: {
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
    formHeader:{
     textAlign:"center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        textAlign:"center",
      },
    },
    buttons: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginBottom:theme.spacing(1)
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom:theme.spacing(1),
    },
    errorMessage: {
      color: "#FF3333",
      fontSize: "1rem",
      fontWeight: 300,
      textAlign:"center",
    },
    logo:{
      contentAlign: 'center',
      width:"50%"
    },
    logoBox:{
      display:"flex", 
      flexDirection:"column" ,
      justifyContent:"center",
      alignItems:"center",
      textAlign: 'center',
    },
    contentCenter:{
      display:"flex", 
      flexDirection:"column" ,
      justifyContent:"center",
      alignItems:"center",
      textAlign: 'center',
    },
    noShow:{
      display:"none"
    }
  }))


  /*
 export const stylesTrial ={
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    stepHeader: {
      textAlign: "left",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        textAlign: "center",
      },
    },
      formHeader:{
       textAlign:"center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
          textAlign:"center",
        },
      },
      buttons: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom:theme.spacing(1)
      },
      button: {
        marginTop: theme.spacing(1),
        marginBottom:theme.spacing(1),
      },
      errorMessage: {
        color: "#FF3333",
        fontSize: "1rem",
        fontWeight: 300,
        textAlign:"center",
      },
      logo:{
        contentAlign: 'center',
        width:"50%"
      },
      logoBox:{
        display:"flex", 
        flexDirection:"column" ,
        justifyContent:"center",
        alignItems:"center",
        textAlign: 'center',
      },

      contentCenter:{
        display:"flex", 
        flexDirection:"column" ,
        justifyContent:"center",
        alignItems:"center",
        textAlign: 'center',
      },
      
    }
  

    */