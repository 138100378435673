import React, { useState, useEffect } from "react"
import { withFB } from "../Firebase/firebasecontext"

import {
  Typography,
  makeStyles,
  ThemeProvider,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core"
import { StaticImage } from "gatsby-plugin-image"
import theme from "./theme"
import Paper from "@material-ui/core/Paper"
import SpinnerButton from "../BasicUiElements/SpinnerButton"
import logo from "../../../src/images/DoryGo_logo.png"
import useSharedClasses from "./FormStyles"
import test from "../../images/DoryHealth_Product_Mobilephone.jpg"
function OrderFormBase(props) {
  const classes = useSharedClasses()
  const [busy, setBusy] = useState(false)
  const [product, setProduct] = useState({})
  const [user, setUser] = useState({})
  
  /*
  
  const [loading, setLoading] = useState(true)
  const [voucherAdded, setVoucherAdded] = useState(false)
  const [voucherInvalid, setVoucherInvalid] = useState(false)
  const [txtVoucher, setTxtVoucher] = useState({})



  const errorMessages = {
    salesChannel: "",
    salesChannelOther: "",
    voucherCode: "",
  }

  const txt = {
    status: "trialphase",
    currentProductLabel: "1 Monat DoryGo",
    currentProductCost: "kostenlos",
    currentProductPeriod: "",
    trialUntil: "30.10.2021",
    newProductLabel: "Monatsabo",
    newProductDisplayCost: "2,50 Euro",
    newProductDisplayPeriod: " pro Woche",
    newProductBillingCost: "9.90 Euro",
    newProductBillingPeriod: "pro Monat",
    currentProduct: "price_1IgEHkGUqcd9SXbMca5V1XKp",
    selectedProduct: "price_1IgEHkGUqcd9SXbMoQY7X49C",
  }

  const getData = () => {
    //dummyfunction for getting the user data
    console.log("getData Called")

    setProduct({ ...product, label: "1 Monat DoryGo", cost: "kostenlos" })
    setUser({
      ...user,
      status: "trialphase",
      currentProductLabel: "1 Monat DoryGo",
      currentProductCost: "kostenlos",
      currentProductPeriod: "",
      trialUntil: "30.10.2021",
      newProductLabel: "Monatsabo",
      newProductDisplayCost: "2,50 Euro",
      newProductDisplayPeriod: " pro Woche",
      newProductBillingCost: "9.90 Euro",
      newProductBillingPeriod: "pro Monat",
      currentProduct: "price_1IgEHkGUqcd9SXbMca5V1XKp",
      selectedProduct: "price_1IgEHkGUqcd9SXbMoQY7X49C",
    })

    setLoading(false)
  }
*/
  const handleButtonClick = () => {
    // filler code
    setBusy(true)
    let authuser = sessionStorage.getItem("authUser")

    props.firebase.doStripeRedirectNew(
      authuser,
      "price_1Jv1IJGUqcd9SXbMqRLMBevK",
      "txr_1IxuPoGUqcd9SXbMVWKceu7t"
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.layout}>
        <Paper className={classes.paper}>
          <div className={classes.logoBox}>
            <StaticImage
                src="../../../src/images/DoryGo_logo.png"
                alt="Icon"
                className={classes.logo}
              />
          </div>

          <Grid container spacing={3}>
          <Grid item xs={12} md={12}>

            <StaticImage
                src="../../images/DoryHealth_Product_Mobilephone.jpg"
                alt="Icon"
              />
          </Grid>
            <Grid item xs={12}>
              <div>
                <Typography variant="h1" className={classes.formHeader}>
                  Ihre Bestellung{" "}
                </Typography>
                <table style={{ width: "100%", borderBottom: "3px solid rgb(212, 212, 212)", width: "100%"  }}>
                  <tbody>
                    <tr >
                      <td style={{ width: "60%" }}>
                        {" "}
                        <strong>DoryGo Service</strong>
                      </td>
                      <td style={{ textAlign: "right" }}>9,90 Euro / Monat</td>
                    </tr>
                    <tr >
                      <td style={{ width: "60%" }}>
                        {" "}
                        <strong>Lieferung</strong>
                      </td>
                      <td style={{ textAlign: "right" }}>kostenlos</td>
                    </tr>
                  </tbody>
                </table>
                <table style={{ width: "100%" }}>
                  <tbody>


                    <tr>
                      <td style={{ width: "60%" }}>
                        <strong>Total</strong>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        9,90 Euro / Monat
                      </td>
                    </tr>
                  </tbody>
                </table>              
              </div>
            </Grid>
           
            <Grid item xs={12}>
              <div className={classes.buttons}>
                <SpinnerButton
                  variant="contained"
                  color="primary"
                  loading={busy}
                  disabled={busy}
                  onClick={handleButtonClick}
                  className={classes.button}
                >
                { busy? <div> Sie werden weitergeleitet </div> : <div>Zur Kasse</div>}
                  
                </SpinnerButton>
              </div>

              <Typography variant="body1">
                Ihnen werden automatisch jeden Monat 9,90 Euro in Rechnung gestellt, bis Sie
                kündigen. 

              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </ThemeProvider>
  )
}

const OrderForm = withFB(OrderFormBase)

export default OrderForm
